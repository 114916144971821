<template>
    <v-container class="down-top-padding pa-0" fluid>
        <v-card class="pa-3 ">
        <v-row>
            <v-col sm="6" cols='12' v-for="course in courses" :key="course.id">
                
                    <v-card 
                   class="mr-3 pa-2"
                        
                        
                        >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <div class="overline mb-4">

                                </div>
                                <v-list-item-title class="headline mb-1">
                                    {{course.name}}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{course.desc}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar
                                tile
                                size="80"
                                v-if="!$vuetify.breakpoint.mobile"
                                ><v-icon size='80' color='primary'>mdi-book-open-variant</v-icon></v-list-item-avatar>
                        </v-list-item>

                        <v-card-actions>
                            <v-btn
                                class='px-5'
                                :to="'/courses/'+course.id+'/edit'"
                                >
                                Перейти
                            </v-btn>
                        </v-card-actions>
                    </v-card>
            </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "index",
        data: () => ({
                courses: [],
                loading:true,
            }),
        methods: {
            fetchData() {
                this.$http.get('courses/editor-courses').then(response => {
                    this.courses = response.data
                    this.loading=false
                })

            }
        },
        mounted(){
            this.fetchData()
        },
        components: {
        }
    };
</script>